import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { Link } from 'gatsby';

import * as routes from '../../constants/routes';
import { withFirebase } from '../Firebase/FirebaseContext';
import { slugify } from '../../utils';

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  playgroup: '',
  error: null,
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne, playgroup } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your own accessible Firebase Database too
        const uid = authUser.user.uid
        let doCreateUser = this.props.firebase.doCreateUser(uid, username, email);

        // Create the playgroup.
        const playgroupId = slugify(playgroup);
        let doCreatePlaygroup = this.props.firebase.doCreatePlaygroup(playgroup, playgroupId, {
          name: username,
          uid: uid
        });

        Promise.all([doCreateUser, doCreatePlaygroup])
          .then(() => {
            this.setState(() => ({ ...INITIAL_STATE }));
            navigate(`/playgroup/${playgroupId}`);
          })
          .catch(error => {
            this.setState({ error });
          });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
      playgroup,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      username === '' ||
      email === '' ||
      playgroup === ''

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="username"
          value={username}
          onChange={event =>
            this.setState({ [event.target.name]: event.target.value })
          }
          type="text"
          placeholder="Full Name"
        />
        <input
          name="email"
          value={email}
          onChange={event =>
            this.setState({ [event.target.name]: event.target.value })
          }
          type="text"
          placeholder="Email Address"
        />
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={event =>
            this.setState({ [event.target.name]: event.target.value })
          }
          type="password"
          placeholder="Password"
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={event =>
            this.setState({ [event.target.name]: event.target.value })
          }
          type="password"
          placeholder="Confirm Password"
        />
        <input 
          name="playgroup"
          placeholder="Playgroup"
          value={playgroup}
          type="text"
          onChange={event =>
            this.setState({ [event.target.name]: event.target.value })
          }          
        />
        <button disabled={isInvalid} type="submit">
          Sign Up
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={routes.SIGN_UP}>Sign Up</Link>
  </p>
);

export { SignUpLink };

export default withFirebase(SignUpForm);
